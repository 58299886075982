import type { Footer } from "@shop-storefront/utils";
import _ from "lodash";
import type { SparFooterProps } from "~/components/sections";

// Not fully implemented yet
/* const getNavigationParent = () => {
  // return sparMainNavigationMock.navigation.parent[0];
  return "";
}; */

// get correct ups section, depending on selected navigation level
const getUspSection = (footer: Footer) => {
  const uspSections = footer.usp;
  if (!uspSections) return [];

  // Not fully implemented yet
  // const navigationParent = getNavigationParent();
  const defaultUsp = uspSections.find((uspSection) => uspSection.navigationLevel === "default");
  if (!defaultUsp) return [];
  // const navigationUsp = uspSections.filter(
  //   (uspSection: SparUspProps) => uspSection.navigationLevel === navigationParent,
  // );
  // const res = navigationUsp.length ? navigationUsp : defaultUsp;

  return defaultUsp.uspList || [];
};

export function resolvePageFooter(footer: Footer): SparFooterProps {
  const footerNavColumns = _.toArray(footer.footerNav).map((column) => {
    return {
      label: column[0].label,
      links: column[0].links,
    };
  });

  return {
    usp: {
      uspList: getUspSection(footer),
    },
    trustArea: {
      areas: footer.trustArea ?? [],
    },
    footerNav: {
      columns: footerNavColumns,
    },
    newsletter: footer.newsletter,
    legalNav: footer.legalNav,
    socials: footer.socialsRecommendations,
  };
}
