<template>
  <div class="socials-list">
    <div class="socials-list__title">{{ socialsTitle }}</div>
    <ul class="socials-list__items" role="menu">
      <li
        v-for="(social, index) in socials"
        :key="index"
        role="none"
        class="socials-list__item"
        :class="isActive(index) ? 'socials-list__item--active' : ''"
      >
        <component
          :is="social.links.length === 1 ? 'a' : 'button'"
          v-if="social.links"
          class="socials-list__item-link btn--clear"
          :aria-label="social.label"
          v-bind="getAttributes(social)"
          :aria-pressed="isActive(index) ? 'true' : 'false'"
          @click="openLinks(social, index)"
        >
          <spar-icon-sprite :symbol="social.icon" />
        </component>

        <div v-if="social.links" class="socials-list__item-list-wrapper">
          <button
            type="button"
            class="socials-list__item-list-close btn--clear"
            @click="closeList()"
          >
            <spar-icon-sprite symbol="close" />
          </button>
          <ul v-if="social.links.length > 1" class="socials-list__item-list" role="menu">
            <li v-for="(link, linkIndex) in social.links" :key="linkIndex" role="none">
              <a
                class="socials-list__item-list-link"
                :aria-label="link.label"
                :href="link.link"
                target="_blank"
                rel="noopener"
              >
                {{ link.label }}
              </a>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import type { SocialLinks } from "@shop-storefront/utils";
import SparIconSprite from "~/components/shared/SparIconSprite/SparIconSprite.vue";
import type { SparSocialsListProps } from "~/components/shared/SparSocialsList/SparSocialsList.types";

defineProps({
  socialsTitle: {
    type: String,
    required: true,
  },
  socials: {
    type: Array as PropType<SocialLinks[]>,
    default: () => [],
  },
}) as SparSocialsListProps;

const activeItem = ref<number | null>(null);

const getAttributes = (item: SocialLinks) => {
  if (item.links?.length === 1) {
    return {
      href: item.links[0].link,
      target: "_blank",
      rel: "noopener",
    };
  }
};

const openLinks = (item: SocialLinks, index: number) => {
  if (item.links?.length === 1) {
    return;
  }

  activeItem.value = index;
};

const isActive = (index: number) => {
  return index === activeItem.value;
};

const closeList = () => {
  activeItem.value = null;
};
</script>

<style lang="scss">
@use "./SparSocialsList.scss";
</style>
